import React,{useState} from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"> 
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <Avatar sx={{ bgcolor:"primary.light"}} >
              <Inventory2OutlinedIcon />
            </Avatar>
            <Typography variant="body2" py={1}>Caja #{row.Caja}</Typography>
          </Stack>  
        </TableCell>
        <TableCell component="th" >{row.Piezas} pza(s)</TableCell>
        <TableCell component="th" >{(row.Estatus==='P')?<Alert severity="warning" sx={{ width: '90%' }}> Escaneo faltante</Alert>:<Alert severity="success" sx={{ width: '90%' }}>Escaneo Completo</Alert>}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" color="text.secondary">
                Productos en caja
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Imagen</TableCell>
                    <TableCell align="center">Descripción</TableCell>
                    <TableCell align="center">Cantidad</TableCell>                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.skus.map((sku) => (
                    <TableRow key={sku.Sku}>
                      <TableCell component="th" scope="row"><img src={`https://pedidos.com/myfotos/${sku.Sku}.jpg`} alt={sku.Sku}/></TableCell>
                      <TableCell color="text.secondary">{sku.Descipcion}</TableCell>
                      <TableCell align="center">{sku.Piezas}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Caja: PropTypes.number.isRequired,
    Piezas: PropTypes.number.isRequired,
    skus: PropTypes.arrayOf(
      PropTypes.shape({
        Sku: PropTypes.string.isRequired,
        Descipcion:PropTypes.string.isRequired,
        Piezas: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default function DetallePedidoTabla({rows}){
    return (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>No. de Caja(s) de Pedido</TableCell>
                <TableCell >Contenido</TableCell>
                <TableCell >Estatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}