import React, {useState,useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DetallePedido from './DetallePedido';
import { Skeleton } from '@mui/material';

import { makeStyles } from '@mui/styles';

import services from '../../services/services'

const useStyles = makeStyles({
    bgSkeleton: {
      backgroundColor: '#E7ECF3',
      borderRadius:'5px',
    },
});

const columns = [
  { id: 'Pedido',   label: 'No. Pedido', minWidth: 170 },
  { id: 'Factura',  label: 'Factura', minWidth: 170 },
  { id: 'Cliente',  label: 'Cliente', minWidth: 170 },
  { id: 'Fecha',    label: 'Fecha', minWidth: 170 },
];



export default function TabPickUP({mostrar,setMostrar}) {
    const [page, setPage]                   = useState(0);
    const [rowsPerPage, setRowsPerPage]     = useState(5);
    const [rows, setRows]                   = useState([]);
    const [detalle,setDetalle]              = useState({isEscaneado:false,Estatus:'P',Nombre:'',FechaFactura:'',Pvs:'S',Precio:0})
    const [rowsP, setRowsP]                 = useState([]); 
    const [escaneo, setEscaneo]                 = useState(''); 

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {setPage(newPage);};    
    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(+event.target.value);
        setPage(0);
    };

     

    useEffect( () => {
        if(mostrar.pedido_num === 0){
            const getData = async () => {
                console.log('Tabla')
                services.getPedidosPickUP()
                .then( response =>{        
                    setRows(response.data)
                })   
            } 
            getData()
        }        
    },[mostrar.pedido_num])

    
    
    useEffect( () => {
        if(mostrar.pedido_num > 0){
            const getData = async () => {
                console.log('Detalle Pedido')
                services.getDetallePedidosPickUP(mostrar.pedido_num)
                .then( response =>{        
                    setRowsP(response.data.tabla)
                    setDetalle({isEscaneado:response.data.isEscaneado,Estatus:response.data.Estatus,Nombre:response.data.Nombre,FechaFactura:response.data.FechaFactura,Pvs:response.data.Pvs,Precio:response.data.Precio})
                })   
              }  
              getData()
        }        
    },[mostrar.pedido_num,escaneo])
    
    function verDetalle(pedido){
        setMostrar({modulo:mostrar.modulo, pedido_num:pedido,libera:false,cartd:false})
    }
    
    return (
        <> 
             
        {(mostrar.pedido_num > 0)?
            <DetallePedido mostrar={mostrar} setMostrar={setMostrar} detalle={detalle} setDetalle={setDetalle} rows={rowsP} setEscaneo={setEscaneo}/>
        :
            (rows.length > 0)? 
            <Paper pt={2} sx={{ width: '100%', overflow: 'hidden' }}>
                <Box  pt={2} px={1} bgcolor="background.default">
                    <Typography variant="h5" fontWeight="medium" component="h2">Entregas</Typography> 
                    <Typography variant="subtitle1" fontWeight="medium" component="h3" pt={1} color="primary" sx={{ borderBottom: 2, width:"20%" }}>Pedidos del día</Typography> 
                </Box>
                <TableContainer sx={{ maxHeight: 440, borderRadius:3, }}>
                    <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth,  backgroundColor: "#fff" }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}
                                    onClick={() => verDetalle(row.Pedido)} >
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    style={{ fontWeight:"medium"}}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            :
            <Skeleton variant="rectangular" height={500} className={classes.bgSkeleton}/>           
        }
        </>
    )
}