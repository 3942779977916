import React, { useState, useRef } from 'react'
import SignatureCanvas from "react-signature-canvas";
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import services from '../../../services/services'

export default function Firma({mostrar}){
    const signatureRef          = useRef({});
    const [firma,  setFirma]    = useState('');
    const [carga,  setCarga]    = useState(false);

    function Save(){
        setCarga(true)
        services.getLibera(mostrar.pedido_num,firma)
        .then( response =>{        
            if(response.data){
                window.location.reload();
            }
        })
    }
    return (
      <>
        <Box  sx={{ flexGrow: 1,  mx: 'auto',p: 1, m: 1, }}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} >
                    <SignatureCanvas 
                        canvasProps={{ 
                            style: {   width: '100%', height: '500px', border: "2px solid #002d75", borderRadius:15, justify:'center',  },
                        }}
                        
                        ref={signatureRef}
                        onEnd={() =>
                            setFirma(
                            signatureRef.current.getTrimmedCanvas().toDataURL("image/jpg")
                            )
                        }
                    />
                
            </Grid>
            {(carga)?null:            
            <>
            <Grid item xs={6}>
                
                <Button  variant="contained" fullWidth size="large" onClick={() => {  signatureRef.current.clear();setFirma('');}}>
                    Modificar
                </Button>
               
            </Grid>
            <Grid item xs={6}>
               
                    <Button variant="contained" fullWidth size="large" onClick={Save}>
                        Guardar
                    </Button>
               
            </Grid>
            </>
            }
          </Grid>
        </Box>
       
      </>
    );
}