import axios from 'axios'
import config from '../config'
//const url = 'http://192.10.2.166:8080/rest';
//const url = 'https://35.238.28.150:8082/API-Rest';
const url = config.url;

class services{
    getLogin(usuario,password){
        return axios.post(url+'/pickUp/getLogin?usuario='+usuario+'&password='+password, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getNumPedidosPickUP(){
        return axios.post(url+'/pickUp/getNumPedidosPickUP', 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getPedidosPickUP(){
        return axios.post(url+'/pickUp/getPedidosPickUP', 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getDetallePedidosPickUP(pedido_num){
        return axios.post(url+'/pickUp/getDetallePedidosPickUP?pedido_num='+pedido_num, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getEscaneaCodigo(pedido_num,codigo){
        return axios.post(url+'/pickUp/getEscaneaCodigo?pedido_num='+pedido_num+'&codigo='+codigo, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getEscaneaCajas(pedido_num,escaneo){
        return axios.post(url+'/pickUp/getEscaneaCajas?pedido_num='+pedido_num+"&escaneo="+escaneo, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getLibera(pedido_num,firma){
        return axios.post(url+'/pickUp/getLibera', 
        {
            data :{
                pedido: pedido_num,
                base64: firma
            }
        });
    }

    getInventario(datePicker){
        return axios.post(url+'/pickUp/getInventario?fecha='+datePicker, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    EscaneoInventario(codigoCaja,num_empleado){
        return axios.post(url+'/pickUp/EscaneoInventario?codigoCaja='+codigoCaja+'&num_empleado='+num_empleado, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getActividades(){
        return axios.post(url+'/pickUp/getActividades', 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }


    getSubirEvidencia(id,evidencia){
        return axios.post(url+'/pickUp/getSubirEvidencia', 
        {
                id_actividad: id,
                base64: evidencia
        });
    }

    getZettle(pedido_num){
        return axios.post(url+'/pickUp/getZettle?pedido_num='+pedido_num, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }

    getEnvioCorreo(pedido_num){
        return axios.post(url+'/pickUp/getEnvioCorreo?pedido_num='+pedido_num, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-control-Allow-Origin' : '*',
                'Access-control-Allow-Methods' : 'GET, PUT, POST, DELETE',
            }
        });
    }
}

export default new services();