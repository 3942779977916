import React, {useState}  from "react";
import { styled, useTheme }         from '@mui/material/styles';
import Box                      from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack'; 
import InputBase from '@mui/material/InputBase';
import logo from '../assets/pedidos-logo.svg'
import logoResponsive from '../assets/pedidos-vresp.svg'
import StatusPickUP     from './componentsDashboard/StatusPickUP';
import CardResume       from './componentsDashboard/CardResume';
import TabPickUP       from './componentsDashboard/TabPickUP';
import FechaHora       from './componentsDashboard/FechaHora';
import Mantenimiento    from './componentsDashboard/Mantenimiento/Mantenimiento';
import Inventory    from './componentsDashboard/Inventory';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor:  (theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: (theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Dashboard() {
  const theme                   = useTheme();
  const [mostrar, setMostrar]   = useState({modulo:'', pedido_num:0, libera:false, cartd: true});
  const [open, setOpen]         = useState(false);
  const [usuario]               = useState({'nombre':(localStorage.getItem('nombre') === undefined)?'':localStorage.getItem('nombre'),'puesto':(localStorage.getItem('puesto') === undefined)?'':localStorage.getItem('puesto')});
  const handleDrawerOpen = () => {setOpen(true);};
  const handleDrawerClose = () => { setOpen(false);};  
  function handleMostrar(text) {setMostrar({modulo:text, pedido_num:0, libera:false,cartd: true});};
  const navigate = useNavigate();

  function cerrarSesion(){
    localStorage.clear()    
    navigate('/');
  }

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="background" elevation={0}>
        <Toolbar>         
          <IconButton  color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start"
            sx={{  marginRight: '20px',  ...(open && { display: 'none' }),  }} >
            <img alt='Pedidos.com' src= {logoResponsive} sx={{ mx:'auto',  py:2, textAlign: 'center', }} />
          </IconButton>
          <Search  sx={{ flexGrow: 1 }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Buscar…" 
              inputProps={{ 'aria-label': 'Buscar' }}
            />
          </Search>
         
          <Box component="div" sx={{  display: 'flex',  alignItems: 'flex-end',  flexDirection: 'column', }}>
            <Item>
                <Typography variant="caption" noWrap component="div" sx={{ fontWeight: 500}}>
                    {usuario.nombre}
                </Typography>
            </Item>
            <Item>
                <Typography variant="caption" noWrap component="div" sx={{ fontWeight: 500, color: 'text.secondary', display: 'block'  }}>
                    {usuario.puesto} 
                </Typography>
            </Item>
          </Box>
          <Stack direction="row" spacing={3}  sx={{ mx:'auto', ml: 3, textAlign: 'center',}}>
             <Avatar sx={{ bgcolor:"primary.dark" }}>{(usuario.length > 0)?usuario.nombre.substring(0,1):'L'}</Avatar>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
        <img alt='Pedidos.com' src= {logo} sx={{ mx:'auto', py:2, textAlign: 'center',}} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ marginTop:'35px' }}>
          {['PickUP','Inventory','Mantenimiento'].map((text, index) => (
            <ListItem button key={text} onClick={() => handleMostrar(text)}>
                    {(mostrar.modulo === text)?
                      <ListItemIcon  sx={{ m:'auto', py:1.2, px:1.2, borderRadius: 3, textAlign: 'center',  bgcolor:'primary.main', color:'#ffffff', }}>
                          {(index === 0)?
                            <StorefrontOutlinedIcon sx={{ fontSize: 38 }}/> 
                            :
                            (index === 1)?
                            <Inventory2OutlinedIcon sx={{ fontSize: 38 }}/> 
                            :
                            (index === 2)?
                            <InventoryOutlinedIcon sx={{ fontSize: 38 }}/> 
                            :
                            <InventoryOutlinedIcon sx={{ fontSize: 38 }}/> 
                          }
                      </ListItemIcon>
                      :
                      <ListItemIcon sx={{ m:'auto', py:1.2, px:1, borderRadius: 3, textAlign: 'center',  bgcolor:'background.blueLight', color:'primary.main', }}>
                        {(index === 0)?
                            <StorefrontOutlinedIcon sx={{ fontSize: 38 }}/> 
                            :
                            (index === 1)?
                            <Inventory2OutlinedIcon sx={{ fontSize: 38 }}/> 
                            :
                            (index === 2)?
                            <InventoryOutlinedIcon sx={{ fontSize: 38 }}/> 
                            :
                            <InventoryOutlinedIcon sx={{ fontSize: 38 }}/> 
                          }
                      </ListItemIcon>
                    }                  
                       
              <ListItemText primary={text} variant="subtitle2" sx={{ ml:2.5,}}/> 
              </ListItem>
          ))}
        </List>

        <List>
          {['Cerrar Sesion'].map((text, index) => (
            <ListItem button key={text} onClick={cerrarSesion}>
              <ListItemIcon  sx={{ m:'auto', py:1.2, px:1.2, borderRadius: 3, textAlign: 'center',  bgcolor:'primary.main', color:'#ffffff', }}>
                {index % 2 === 0 ? <LogoutOutlinedIcon sx={{ fontSize: 35 }} /> : <MailIcon />}
              </ListItemIcon>

              <ListItemText primary={text} sx={{ ml:2.5,}} />
            </ListItem>
          ))}
        </List>
       
        
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
          {(mostrar.cartd)?
            <Grid container>
                <Grid mt={1} item xs={12} sm={6}>
                    <Item my={1} p={2}>
                      <Typography variant="h1">Resumen</Typography>
                      <Typography variant="subtitle1"  sx={{ color: 'text.secondary', }} > <FechaHora/> </Typography>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Item px={2}>
                        <StatusPickUP/> 
                    </Item>
                </Grid>                
            </Grid> 
            :
            null
          }                  
        <Typography paragraph>
            {(mostrar.cartd && mostrar.modulo !== 'Mantenimiento' && mostrar.modulo !== 'Inventory')?
              <CardResume setMostrar={setMostrar}/>
              :
              null
            }
            
        </Typography>
        <Typography paragraph>
            {(mostrar.modulo === 'PickUP')?
              <TabPickUP mostrar={mostrar}  setMostrar={setMostrar}/>
              :
              (mostrar.modulo === 'Mantenimiento')?
              <Mantenimiento mostrar={mostrar}  setMostrar={setMostrar}/>
              :
              (mostrar.modulo === 'Inventory')?
              <Inventory pedido_num={mostrar.pedido_num}  mostrar={mostrar} setMostrar={setMostrar} />
              :
              null
            }
        </Typography>
      </Box> 
      <Divider light />
    </Box>
    </>
  );
}