import React,{useRef,useState} from "react";
import Webcam from "react-webcam";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import Alert from '@mui/material/Alert';

import services from '../../services/services';

export default function Screenshot({id,setIsRecarga,setSkeleton,skeleton}){
    const [open, setOpen]   = useState(false);
    const [error, setError] = useState('sdas');
    const img               = useRef(null);
    const handleOpen        = () => setOpen(true);
    const handleClose       = () => setOpen(false);
    const videoConstraints = {
      facingMode: { exact: "environment" }
    };

    function showImg(){
        if(img.current.getScreenshot() !== ''){             
            setSkeleton(true)
            const base64 =    img.current.getScreenshot();       
            services.getSubirEvidencia(id,base64)
            .then( response =>{ 
              setSkeleton(false) 
               if(response.data){
                handleClose() 
                setIsRecarga(base64)                  
              }else{
                setError('No se cargo correctamente')
              }
            })
        }        
    }

    return (
        <div>
        <Button variant="contained" fullWidth size="Large" onClick={handleOpen}><AddAPhotoOutlinedIcon/></Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box p={8} justifyContent="center"  sx= {{margin:'auto' }} >
            
                
                <Webcam ref={img} videoConstraints={videoConstraints} sx= {{margin:'auto',justifyContent: 'center' }} />
                {(skeleton)?
                  null
                  :
                  <Button fullWidth size="large" onClick={()=>{
                      showImg()
                  }} variant="contained" textTransform="inherit">Tomar Foto</Button>
                }
                
                {(error !== '')?
                  <Alert severity="error" sx={{ justifyContent:'center' }}>  
                    <Typography>{error}<strong> Captura otra</strong> 
                    </Typography>
                  </Alert>
                  :
                  null
                } 
           
          </Box>
        </Modal>
      </div>  
    )
}