import React, {useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import services from "../services/services";

const useStyles = makeStyles({
    root: {
      color: "white",
      backgroundColor: "transparent",
      border: 1,
      borderColor: "white",
      "&.Mui-focused": {
        color: "white",
        backgroundColor: "transparent",
        border: 1,
    
      },
      "&:before": {
        borderColor: "white",
        border: 1,
      },
      "&:hover:not(.Mui-focused):before": {
        borderColor: "white"
      },
      "&:after": {
        // focused
        borderColor: "white"
      }
    },
    input: {
      "&::selection": {
        backgroundColor: "transparent",
        color: "white"
      }
    }
  });
  const useLabelStyles = makeStyles({
    root: {
      color: "white",
      "&.Mui-focused": {
        color: "white"
      }
    }
  });

export default function Login(){
    const classes = useStyles();
    const labelClasses = useLabelStyles();
    
    const navigate                  = useNavigate();
    const [formValue, setFormValue] = useState({usuario:"",password:""});

    const cambio = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormValue(values => ({...values, [name]: value}))
    }
    useEffect( () => {
        const num_empleado=localStorage.getItem('num_empleado');
        if(num_empleado !== undefined && num_empleado !==null){
            if(num_empleado !== "" && num_empleado !== ""){
                navigate("/Dashboard")
            }            
        }   
    })

    

    function ingresar(e){
        e.preventDefault();
        if(formValue.usuario !== '' && formValue.password !== ''){
            try{
                services.getLogin(formValue.usuario,formValue.password)
                .then( response =>{ 
                    if(response.data.num_empleado > 0){
                        localStorage.setItem('num_empleado',response.data.num_empleado);
                        localStorage.setItem('nombre',      response.data.nombre);
                        localStorage.setItem('puesto',      response.data.puesto);
                        navigate("/Dashboard")
                    }else{
                    }
                })
            }catch(e){
                alert("Error servidor")
            }   
        } 
                 
    }
  
    
    return(
        <Box style={{  background: 'linear-gradient(180deg, rgba(54, 85, 165, 0.9), rgba(19, 32, 64, .98) 100%)', }}>          
            <Box sx={{ width: '100%',alignItems: 'center', justifyContent: 'center',color: 'text.light', }}>
                <Grid container                   
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}>
                        <Grid item xs={12}>
                            <Item>
                                <Box justifyContent="center">
                                    <img src='https://pedidos.com/myfotos/pickup/logo/pedidos-white.png' className='img-fluid ' alt='Pedidos.com' />
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item mt={4}  p={4} >
                                <form noValidate onSubmit={ingresar}>
                                    <TextField  color="white" focused label="Usuario" type="text" name="usuario" fullWidth id="fullWidth" margin="dense" onChange={cambio} InputProps={{ classes: classes }} InputLabelProps={{ classes: labelClasses }} />
                                    <TextField  color="white" focused label="Contraseña" type="password" name="password" fullWidth id="fullWidth" margin="dense" onChange={cambio} InputProps={{ classes: classes }} InputLabelProps={{ classes: labelClasses }} />
                                    <Stack spacing={2}>
                                        <Button style={{  background: '#FFFFFF',color:'#3655a5',margin:'2em 0',textTransform:'initial' }} type="submit" variant="contained" size="large">Ingresar</Button>
                                    </Stack>  
                                </form> 
                            </Item>
                        </Grid>
                </Grid>
            </Box>       
        </Box>     
    )
}