import React,{useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { ClassNames } from '@emotion/react';
import { Skeleton } from '@mui/material';
import services from '../../services/services';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    bgSkeleton: {
      backgroundColor: '#E7ECF3',
      borderRadius:'5px',
    },
});


 function CardResume  ({setMostrar}){ 
  const [numeros,  setNumeros]  = useState({});   
  const [skeleton, setSkeleton] = useState(true);
  const classes = useStyles();

  useEffect( () => {
    console.log('is numeros')
    setSkeleton(true)
    const getData = async () => {
      await services.getNumPedidosPickUP()
      .then( response =>{        
        setNumeros(response.data)
        setSkeleton(false)
      })   
    }
    getData()
  },[])
  return (
    <>
      <Grid container spacing={3} my={1}>
        <Grid item sm={4} xs={12}>
          <Item>
          {skeleton?
            <Skeleton variant="rectangular" height={100}  className={classes.bgSkeleton}/>
            :
            <Card className={ClassNames.cardBox} sx={{ borderRadius:3 }} onClick={() => { setMostrar({modulo:'PickUP',pedido_num:0, libera:false, cartd: true})}}>
              <CardActionArea>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={8} md={9}>
                          <Item>
                            <Typography variant="overline" display="block" sx={{ letterSpacing: 3, lineHeight: 4, color: 'text.secondary', }}>Pick UP</Typography>
                            <Typography variant="subtitle1" display="block"sx={{ fontWeight: 'medium' }}> Pedidos por entregar </Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <Item sx={{ mx:'auto', bgcolor:'#c3cbe4', width:'50px', mt: 5, mr: 1, borderRadius: 5, textAlign: 'center',}}>
                              <Typography color='primary.dark' sx={{ p: 2,fontWeight:'bold' }}>{numeros.pickUp} </Typography>
                          </Item>
                        </Grid>
                    </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          }       
          </Item>  
        </Grid> 
        <Grid item sm={4} xs={12}>
          <Item>
            {skeleton?
              <Skeleton variant="rectangular" height={100}  className={classes.bgSkeleton}/>
              :
              <Card className={ClassNames.cardBox} sx={{ borderRadius:3 }} onClick={() => { setMostrar({modulo:'Inventory',pedido_num:0, libera:false, cartd: true})}}>
                <CardActionArea>
                    <CardContent >
                        <Grid container spacing={1}>
                            <Grid item xs={8} md={9}>
                              <Item>
                                <Typography variant="overline" display="block" sx={{ letterSpacing: 2, lineHeight: 4, color: 'text.secondary', }}>  Inventario</Typography>
                                <Typography variant="subtitle1" display="block"sx={{ fontWeight: 'medium' }}> Cajas en Pick Up </Typography>
                              </Item>
                            </Grid>
                            <Grid item xs={4} md={3}>
                              <Item sx={{ mx: 'auto',  bgcolor: '#D7D1EA', width: '50px', mt: 5, mr: 1, borderRadius: 5, textAlign: 'center',}}>
                                <Typography color='#593FA6' sx={{ p: 2,fontWeight:'bold' }}> {numeros.inventario} </Typography>
                              </Item>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>   
              </Card>
            }  
          
          </Item>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Item>
              {skeleton?
                <Skeleton variant="rectangular" height={100}  className={classes.bgSkeleton}/>
                :
                <Card  className={ClassNames.cardBox} sx={{ borderRadius:3 }} onClick={() => { setMostrar({modulo:'Mantenimiento',pedido_num:0, libera:false, cartd: true})}}>
                  <CardActionArea>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={8} md={9}>
                            <Item> 
                              <Typography variant="overline" display="block" sx={{ letterSpacing: 3, lineHeight: 4,color: 'text.secondary', }}> Mantenimiento</Typography>
                              <Typography variant="subtitle1" display="block"sx={{ fontWeight: 'medium' }}> Actividades del día</Typography>
                            </Item>
                          </Grid>
                          <Grid item xs={4} md={3}>
                              <Item sx={{ mx:'auto', bgcolor:'#FDF2E6', width:'50px', mt: 5, mr: 1, borderRadius: 5, textAlign: 'center',}}>
                              <Typography color='secondary.main' sx={{ p: 2,fontWeight:'bold' }}>{numeros.mantenimiento}</Typography>
                            </Item>
                          </Grid>
                        </Grid>
                      </CardContent>
                  </CardActionArea>
                </Card>
              }              
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

export default CardResume;