import React from 'react';
import {BrowserRouter as Router,  useRoutes,} from "react-router-dom"
import Login from './components/Login';
import Qr from './components/componentsDashboard/Liberacion/Qr';
import Dashboard from './components/Dashboard';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/ui/theme'

const IndexRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "Login", element: <Login /> },
    { path: "Dashboard", element: <Dashboard /> },
    { path: "Qr/:valor", element: <Qr/> },
    // ...
  ]);
  return routes;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <IndexRoutes />
      </Router>
    </ThemeProvider>
  );
}
export default App;

