import React,{useEffect,useState} from "react";
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Skeleton } from '@mui/material';

import services from '../../../services/services';
import Screenshot from '../../modal/Screenshot'
import LinearProgressWithLabel from '../../loader/LinearProgressWithLabel'
import Imagen from '../../modal/Imagen'

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgSkeleton: {
      backgroundColor: '#E7ECF3',
      borderRadius:'5px',
    },
  }),
);


export default function Mantenimiento({mostrar,setMostrar}){
    const [actividades, setActividades]     = useState({Tabla:[],Completas:0,Pendientes:0});    
    const [progress, setProgress]           = useState(0);
    const [isRecarga, setIsRecarga]         = useState(null);
    const [skeleton, setSkeleton]           = useState(true);
    const classes = useStyles();

    useEffect( () => { 
        console.log('is mantenimiento')  
        setSkeleton(true)
        const getData = async () => {
            services.getActividades()
            .then( response =>{
                setActividades(response.data)
                setProgress(((response.data.Completas*100)/response.data.Tabla.length))
                setSkeleton(false)
            })   
        }        
        getData()
    },[isRecarga])

    return(       
        <>  
            <Button sx={{ p: 2, bgcolor:"white.main", borderRadius:8, color:"primary" }} onClick={() => { setMostrar({modulo:null, pedido_num:mostrar.pedido_num, libera:false,cartd: true})}}> <ArrowBackIosOutlinedIcon/> </Button>
            <Box my={4} sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>  
                    <Grid item xs={6} md={4}>
                        <Item> 
                            {(skeleton)?
                                <Skeleton variant="rectangular" height={100} className={classes.bgSkeleton}  />
                                :   
                                <Card bgcolor='none'>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            Total de actividades
                                        </Typography>
                                        <Typography variant="h2" color="text.secondary" gutterBottom>
                                            {actividades.Tabla.length}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            }                            
                        </Item>
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Item>
                            {(skeleton)?
                                <Skeleton variant="rectangular" height={100} className={classes.bgSkeleton}/>
                                :   
                                <Card bgcolor='none'>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            Completadas
                                        </Typography>
                                        <Typography variant="h2" color="text.secondary" gutterBottom>
                                            {actividades.Completas}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            }                    
                        </Item>
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Item>
                            {(skeleton)?
                                <Skeleton variant="rectangular" height={100} className={classes.bgSkeleton}/>
                                :   
                                <Card bgcolor='none'>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            Pendientes
                                        </Typography>
                                        <Typography variant="h2" color="text.secondary" gutterBottom>
                                            {actividades.Pendientes}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            }     
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item>    
                            <Box my={2} sx={{ width: '100%' }}>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Progreso...
                                </Typography>
                                {(skeleton)?
                                    <Skeleton variant="rectangular" className={classes.bgSkeleton} />
                                    :   
                                    <LinearProgressWithLabel progress={progress}/>
                                }                        
                            </Box>
                        </Item>
                    </Grid>
                    {(actividades.Pendientes===0 && !skeleton)?
                        <Grid item xs={12} md={12} >
                            <Item>
                                <Box>
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert severity="success">
                                            <AlertTitle>Actividades del día completas!</AlertTitle>
                                                La limpieza del "Pick Up Center" <strong>se realizo correctamente.</strong>
                                        </Alert>
                                    </Stack>
                                </Box>
                            </Item>
                        </Grid>
                        :null
                    }                    
                </Grid>
            </Box>
        
            {(skeleton)?
                <Skeleton variant="rectangular" height={800} className={classes.bgSkeleton}/>
                :   
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100' }} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                            <TableCell colSpan={2}>Descripción de Actividad</TableCell>
                            <TableCell>Horario</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Evidencia</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {actividades.Tabla.map((object, index) => (
                            <TableRow key={index}>
                                <TableCell> 
                                    <ListItemAvatar>
                                    <Avatar sx={{ bgcolor:"secondary.light" }}>
                                        <CleaningServicesIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                </TableCell>
                                <TableCell>{object.actividad}</TableCell>
                                <TableCell>{object.hora_inicio} a {object.hora_fin}</TableCell>             
                                <TableCell>
                                    {(object.isRegis > 0)?
                                    (object.estatus === 'A')?
                                    <Alert  severity="success">
                                        Completo
                                    </Alert>
                                    :
                                    <Alert  severity="error">
                                        Completo con retraso
                                    </Alert>
                                    :
                                    <Alert severity="warning" textAlign="center">
                                        Incompleta
                                    </Alert>                        
                                    }         
                                </TableCell>
                                <TableCell align="right">
                                    {(object.img.length > 0)?<Imagen images={object.img}/>:null}                                                                
                                    <Screenshot id={object.id} setIsRecarga={setIsRecarga} setSkeleton={setSkeleton} skeleton={skeleton}/>                   
                                </TableCell>
                            </TableRow>
                        ))}                
                        </TableBody>
                    </Table>
                </TableContainer>
            } 
        </>        
    )
}
