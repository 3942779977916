import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import QrReader from 'react-qr-reader';
import Typography from '@mui/material/Typography';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import Alert from '@mui/material/Alert';

import services from '../../services/services'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
 
};

export default function ScanningBox({tipo,pedido_num,setDatePicker,setEscaneo}) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  function handleScan (data) {
    if (data) {  
      handleClose()  
      setError('')   
      if(data !== ''){
        if(tipo === 'CodeBox'){
          services.getEscaneaCajas(pedido_num,data)
          .then( response =>{   
              console.log("Escaneado "+response.data.estatus)     
              if(response.data.estatus === 'OK'){
                setEscaneo(data)       
              }else{
                handleOpen();
                setError(response.data.estatus)
              }
          }) 
        }else if(tipo === 'CodeBoxInventory'){
          console.log("Escaneo Inv: "+data+" "+localStorage.getItem('num_empleado'))
          services.EscaneoInventario(data,localStorage.getItem('num_empleado'))
          .then( response =>{        
              if(response.data.estatus === 'OK'){
                setDatePicker(new Date())
              } else{
                handleOpen();
                setError(response.data.estatus)
              }
          }) 
        }        
      }else{
          alert('El codigo no puede ser vacio') 
      }         
    }
  }

  return (
    <Box component="div">
      <Button variant="contained" color="secondary" size="large" startIcon={<QrCodeOutlinedIcon />} onClick={handleOpen} sx={{ py:"15px",bgcolor:"secondary.light", color:"secondary.contrastText", textTransform:"inherit" }}>Escaneo QR Caja</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box id="modal-modal-description">
              <QrReader
                delay={300}         
                onScan={handleScan}
                style={{ width: '100%' }}
              />
              <Box>
                {(error !== '')?
                  <Alert severity="error" sx={{ justifyContent:'center' }}>  
                    <Typography>{error}<strong> Intente otro</strong> 
                    </Typography>
                  </Alert>
                  :
                  null
                }                                
              </Box>
          </Box>
          
        </Box>
      </Modal>
    </Box>
  );
}