import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';

import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const style = { 
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#E7ECF3',
  mx:'auto',
 borderRadius:'30px',
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


export default function Imagen({images}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Button variant="outlined" fullWidth size="Large" onClick={handleOpen}><ImageSearchOutlinedIcon/></Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box id="modal-modal-description" sx={style}> 
            <Box>
              <Paper  elevation={0}
                sx={{ alignItems: 'center',  height: 'auto',  py: 2, px:4, bgcolor: 'background.default', }}>
                <Typography variant="subtitle1" fontWeight="bold">{images[activeStep].label}</Typography>
                <Typography variant="body1" color= "text.secondary" fontWeight="medium">{images[activeStep].date}</Typography>
              </Paper>
              <AutoPlaySwipeableViews sx={{ p: '4em'}}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.label}  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box sx={{ p:'2em'}}>
                      <Paper elevation={4}  component="img"
                        sx={{
                          display: 'block',
                          overflow: 'hidden',
                          width:'490px',
                          height: '490px',
                          mx: 'auto',
                          borderRadius:'15px',
                         

                        }}
                        src={step.base64}
                        alt={step.label}
                      />
                      </Box>
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                   Siguiente
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                   Atrás
                  </Button>
                }
              />
            </Box>       
         
          
        </Box>
      </Modal>
    </div>
  );
}