import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import QrReader from 'react-qr-reader';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';

import services from '../../services/services'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
 
};

export default function ScanningCode({pedido_num,setDetalle,detalle}) {
  const [open, setOpen]   = useState(false);
  const [error, setError] = useState('Error');
  const handleOpen        = () => setOpen(true);
  const handleClose       = () => setOpen(false);

  function handleScan (data) {
    if (data) {      
      if(data !== ''){
        handleClose()       
        services.getEscaneaCodigo(pedido_num,data)
        .then( response =>{        
            if(response.data === 'OK'){
              setDetalle({isEscaneado:detalle.isEscaneado,Estatus:'R',Nombre:detalle.Nombre,FechaFactura:detalle.FechaFactura,Pvs:detalle.Pvs})
            }else{
              setError(response.data)
              handleOpen()
            }
        })        
      }else{
        setError('Codigo Incorrecto') 
        handleOpen()
      }         
    }
  }

  return (
    <div>
      <Button variant="contained" color="secondary" size="large" startIcon={<QrCodeScannerOutlinedIcon />}  onClick={handleOpen} sx={{ py:"15px",bgcolor:"secondary.light", color:"secondary.contrastText", textTransform:"inherit"}}>QR Código</Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
        <Box sx={style}>
          <Box id="modal-modal-description">
              <QrReader delay={300} onScan={handleScan} style={{ width: '100%' }}/>
              {error}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}