import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import StorefrontIcon from '@mui/icons-material/Storefront';



const label = { inputProps: { 'aria-label': 'Switch demo' } };
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box sx={{ p: 0.8, m: 0.8,  borderRadius: 8,
        ...sx, }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function StatusPickUP() {
  return (
    <Card>
      <CardContent>
      <Box mx="auto" sx={{  display: 'flex', flexDirection: 'flex-start', bgcolor: 'background.paper',width:'100' }} >
        <Item sx={{ flexGrow: 1 }}>
              <Typography variant="h5" my={1} sx={{ fontWeight: 'bold',lineHeight: 1, }}><StorefrontIcon /> Pick Up</Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary', }} > Abierto a las 07:00:00 </Typography>
        </Item>      
        <Item alignItems="Center" alignContent="Center" p={2}>
              <Switch sx={{ mx:'auto', mt: 3, textAlign: 'center',}} p={2} my={4} {...label} defaultChecked />
        </Item>
      </Box>
      </CardContent>
    </Card>
  );
}