import React, {useState,useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';


import services from '../../services/services'
import DatePickerDMY from '../DatePickerDMY.js'
import ScanningBox from '../modal/ScanningBox'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Button from '@mui/material/Button';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    bgSkeleton: {
      backgroundColor: '#E7ECF3',
      borderRadius:'5px',
    },
});

function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box sx={{ bgcolor: 'background.white',py: 1,  m: 1, borderRadius: 1, textAlign: 'center', fontSize: '1rem',   fontWeight: '700',...sx,  }}
        {...other}
      />
    );
  }
  
  Item.propTypes = {
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

const columns = [
  { id: 'Pedido',   label: 'Pedido', minWidth: 170 },
  { id: 'Caja',  label: 'Caja', minWidth: 170 },
  { id: 'FechaIngreso',  label: 'Fecha Ingreso', minWidth: 170 },
];


export default function Inventory({mostrar,setMostrar}) {    
    const [page, setPage]                   = useState(0);
    const [rowsPerPage, setRowsPerPage]     = useState(5);
    const [rows, setRows]                   = useState([]); 
    const [datePicker, setDatePicker]       = useState(new Date());
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    

    useEffect( () => {
        const getData = async () => {
            console.log('inventory')
            const fecha = new Date(datePicker);
            services.getInventario(fecha.getDate()+'/'+(fecha.getMonth()+1)+'/'+fecha.getFullYear())
            .then( response =>{        
                setRows(response.data)
            })   
        }  
        getData()
    },[datePicker])
    

   
  
    
    return (
        <>  
        <Button sx={{ p: 2, bgcolor:"white.main", borderRadius:8, color:"primary" }} onClick={() => { setMostrar({modulo:'', pedido_num:0, libera:mostrar.libera,cartd: mostrar.cartd})}}> <ArrowBackIosOutlinedIcon/> </Button>      
            <Box mt={4} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>  
                    <Grid item xs={4} md={4} >
                        <Item> 
                            <Card bgcolor='none'>
                                <CardContent >
                                <Grid container spacing={2}>
                                    <Grid item xs={8} md={8}>
                                         <Item>
                                            <Typography textAlign="left" sx={{ mb: 1.5 }}  color="text.secondary">
                                                Cajas en Pick Up 
                                            </Typography>
                                            <Typography  textAlign="center" variant="h2" color="text.secondary">
                                                {(rows.length > 0)?rows.length:0}
                                            </Typography>
                                         </Item>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Item sx={{ mx:'auto',  bgcolor: '#D7D1EA',  mt: 3,  borderRadius: 3, textAlign: 'center',}}>
                                            <Typography color='#593FA6' sx={{ p: 2,fontWeight:'bold', }}><Inventory2OutlinedIcon sx={{ fontSize: 32 }}/></Typography>
                                        </Item>
                                    </Grid>
                                </Grid>      
                                </CardContent>
                            </Card>                      
                        </Item>
                    </Grid>
                    <Grid item xs={4} md={4} >
                        <Item> 
                            <Card bgcolor='none'>
                                <CardContent >
                                <Box sx={{ justifyContent: 'flex-end',  py: 1.5 }} >
                                    <Item>
                                        <DatePickerDMY datePicker={datePicker} setDatePicker={setDatePicker}/>
                                    </Item>
                                </Box>
                                </CardContent>
                            </Card>                      
                        </Item>
                    </Grid>
                    <Grid item xs={4} md={4} >
                        <Item> 
                            <Box mt={6}>
                                <ScanningBox  tipo='CodeBoxInventory' pedido_num={null}  setDatePicker={setDatePicker} setEscaneo={null}/> 
                            </Box>            
                        </Item>
                    </Grid>          
                </Grid>
            </Box>
            {(rows.length > 0)?
            <> 
            <Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Box px={1} bgcolor="background.default">
                        <Typography variant="h5" fontWeight="medium" component="h2">Inventario</Typography> 
                        <Typography variant="subtitle1" fontWeight="medium" component="h3" pt={1} color="primary" sx={{ borderBottom: 2, width:"20%" }}>Todo</Typography> 
                    </Box>
                    <TableContainer component={Paper} sx={{ maxHeight: 440, borderRadius:3 }}>
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead>
                                <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: "#fff" }}
                                    >
                                    {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align} >
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            </> 
            :
            <Skeleton variant="rectangular" height={500} className={classes.bgSkeleton}/>           
        }
        </>
    )
}