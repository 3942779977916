import { createTheme } from '@mui/material/styles';


export default createTheme ({
    palette:{
        common: {
           blue: '#3655a5',
           orange:"#f1861c",
           white: '#ffffff',
        },
        primary:{
            light: '#5e77b7',
            main: '#3655a5',
            dark: '#002d75',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffb750',
            main: '#f1861c',
            dark: '#a85d13',
            contrastText: '#000',
          },
        white: {
            main:  '#FFFFFF',
          },
        background: {
            paper :'#ffffff',
            default:'#F6F6F9',
            defaultT:'#e8eaf6',
            blue: '#3655a5',
            blueLight:'#F3F5FA'

        },
        text: {
          primary: '#1D232E',
          secondary:'#404651', 
          light:'#FFFFFF',
        },
        
    },
    typography: {
        fontFamily: 'Poppins ,sans-serif',
        h1:  {
          fontWeight: "700",
          fontSize: '2.6rem',
          },
         h2:  {
            fontWeight: "500",
            fontSize: '2.3rem',
            },
      },
    shadows:{
      4:"0px 18px 24px 0px rgba(167, 176, 192, 0.3)",
    },

});