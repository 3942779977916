import React,{useState} from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Skeleton } from '@mui/material'

import Firma    from '../componentsDashboard/Liberacion/Firma'
import ScanningBox   from '../modal/ScanningBox'
import ScanningCode   from '../modal/ScanningCode'
import DetallePedidoTabla from '../componentsDashboard/DetallePedidoTabla';
import services from '../../services/services'

 const DetallePedido = ({mostrar,setMostrar,detalle,setDetalle,rows,setEscaneo}) => {  
    const [envarQr,setEnviarQr] = useState('')

    function getZettle(){
        services.getZettle(mostrar.pedido_num)
        .then( response =>{  
            console.log(response.data)       
            if(response.data.estatus === 'OK'){
                setMostrar({modulo:mostrar.modulo, pedido_num:mostrar.pedido_num, libera:true,cartd: mostrar.cartd})
            }
        }) 
    }

    function getEnvioCorreo(){
        setEnviarQr('Enviando...')
        services.getEnvioCorreo(mostrar.pedido_num)
        .then( response =>{  
            setEnviarQr(response.data)   
        }) 
    }

    return (
        <>        
        {(mostrar.libera)?
            <Firma mostrar={mostrar}/>
            :  
            <>                  
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <Item>
                      <Button sx={{ p: 2, bgcolor:"white.main", borderRadius:8, color:"primary" }} onClick={() => { setMostrar({modulo:mostrar.modulo, pedido_num:0, libera:false,cartd: true})}}> <ArrowBackIosOutlinedIcon/> </Button>
                    </Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item>
                             <Typography variant='h1' justifyContent="right" color="text.secondary">Pedido #{mostrar.pedido_num}</Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            <Box my={2}>
                <Grid container xs={12}>
                    <Grid item xs={9} sm={8}>
                        <Item my={3} px={1}>
                            <Typography variant="h5" fontWeight="medium" component="h2">Detalles </Typography>   
                            {(detalle.Nombre !== '')?<Typography mt={2} variant="subtitle1" component="h3" color="text.secondary">Cliente: {detalle.Nombre}</Typography>:<Skeleton variant="rectangular" />}   
                            {(detalle.FechaFactura !== '')?<Typography variant="subtitle1" color="text.secondary">Fecha de orden: {detalle.FechaFactura}</Typography>:<Skeleton variant="rectangular" />}   
                            {(detalle.Nombre !== '')?<Typography variant="subtitle1" color="text.secondary">Pago: {(detalle.Pvs==='S')?`PAGO CONTRA ENTREGA`:`LINEA (PAGADO)`} </Typography>:<Skeleton variant="rectangular" />}   
                            {(rows.length > 0)?<Typography variant="subtitle1" color="text.secondary">Cajas: {rows.length} </Typography>:<Skeleton variant="rectangular" />}   
                        </Item>  
                    </Grid>  
                    <Grid item  sm={4}>
                        <Box pt={3} >
                            {(detalle.Precio > 0)?<Typography variant="h5" fontWeight="medium" component="h2">${detalle.Precio.toFixed(2)}</Typography>:<Skeleton variant="rectangular" />}                            
                            <Typography variant="subtitle2" fontWeight="medium" component="h3" color="text.secondary" > Total de pedido</Typography>
                        </Box>                             
                        <Box mt={3}>
                            {(rows.length > 0)?
                                (detalle.Estatus === 'P')?
                                <Item>
                                <ScanningCode  setDetalle={setDetalle} detalle={detalle} pedido_num={mostrar.pedido_num} />
                                <Box mt={3}>
                                <Item>
                                {(envarQr === '')?                                
                                    <Button variant="contained" color="secondary" size="large" onClick={getEnvioCorreo} sx={{ py:"10px",bgcolor:"secondary.light", color:"secondary.contrastText", textTransform:"inherit"}}>Enviar Qr</Button>
                                    :
                                    <Typography variant="subtitle2" fontWeight="medium" component="h3" color="text.secondary" >{envarQr}</Typography>
                                }
                                </Item>
                                </Box>
                                </Item>
                                :
                                (detalle.isEscaneado)?
                                    (detalle.Pvs === 'S')?
                                    <Button variant="contained" color="secondary" fullWidth size="large" onClick={getZettle}>Verifica Pago</Button>
                                    :
                                    <Button variant="contained"  color="primary" fullWidth size="large" onClick={()=>{setMostrar({modulo:mostrar.modulo, pedido_num:mostrar.pedido_num, libera:true, cartd: mostrar.cartd})}}>Siguiente</Button>
                                :
                                <Item>                                   
                                    <ScanningBox tipo='CodeBox' pedido_num={mostrar.pedido_num}  setDatePicker={null} setEscaneo={setEscaneo}/>
                                </Item>
                                :
                                <Skeleton variant="rectangular" height={50}/>            
                            }                                                   
                        </Box> 
                    </Grid>   
                                    
                </Grid>  
            </Box>
            {(rows.length > 0)?
                <DetallePedidoTabla rows={rows}/>
                :
                <Skeleton variant="rectangular" height={500}/>  
            }
            
            </> 
        }
        </>
        
    )
}

export default DetallePedido;